.tableStatusContainer {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    line-height: 7px;
    gap: 7px;
    font-weight: 700;
    margin-top: 7px;

}

.tableStatus {
    height: 7px;
    width: 7px;
    border-radius: 100%;
}