.container {
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    width: 90%;
    margin: auto;
    margin-top: 25px;
}

h1 {
    font-weight: 700;
    font-size: x-large;
}

h3 {
    font-weight: 600;
}

h4 {
    font-weight: 500;
}

.basicProfileCard h4 {
    color: white;
    font-weight: 600;
    text-transform: uppercase;
}

.basicProfileCard h2 {
    color: white;
    font-weight: 500;
}


.header {
    display: flex;
    justify-content: space-between;
}

.flexBoxRow {
    display: flex;
    align-items: center;
    gap: 15px;

}

.textGray {
    color: #999;
    font-weight: 600;
}

.linkGray {
    color: #888;
    font-weight: 600;
    transition: .25s ease-in-out;
}

.linkGray:hover {
    color: #444;
}

.btnAddProvider {
    background-color: #f87d4e;
    border-radius: 25px;
    font-weight: 600;
    border: #f87d4e;
    height: 50px;
    box-shadow: 2px 2px 12px -5px rgba(0, 0, 0, 0.5);
}

.btnAddProvider:hover {
    background-color: #f87d4ecc;
    transition: .25s ease-in-out;
}

.btnAddProvider:focus {
    background-color: #f87d4ecc;
}

.content {
    margin: 35px 0px;
    display: flex;
    gap: 25px;
}

.profileCard {
    background-color: white;
    width: 70%;
    border-radius: 25px;
    box-shadow: 0px 0px 12px -7px rgba(0, 0, 0, 0.25);
    border: rgba(0, 0, 0, 0.15) solid 0.1px;
    padding: 35px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

::-moz-selection {
    /* Code for Firefox */
    color: #F87D4E;
    background: #F87D4E0f;
}

::selection {
    color: #F87D4E;
    background: #F87D4E0f;
}

.detailsLogoContainer {
    width: 65px;
    height: 65px;
    line-height: 65px;
    border-radius: 25%;
    overflow: hidden;
    text-align: center;
    border: 1px solid #3AB44D33;
    box-shadow: 20px 20px 60px #bebebe99, -20px -20px 60px #ffffff99;
    background-color: white;
    margin-bottom: -10px;
}

.progressSpinContainer {
    height: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profileCardHeader {
    display: flex;
    justify-content: space-between;
}

.tableContainer {
    border-radius: 25px;
    overflow: hidden;
    border: 1px solid #eaebf3
}

.btnShadow {
    box-shadow: 3px 5px 20px -7px rgba(0, 0, 0, 0.25);
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    border-radius: 25px;
    padding: 0px 20px;
}

.sideContent {
    width: 35%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.contactCard {
    background-color: white;
    border-radius: 25px;
    width: 100%;
    box-shadow: 0px 0px 12px -7px rgba(0, 0, 0, 0.25);
    border: rgba(0, 0, 0, 0.15) solid 0.1px;
    padding: 35px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.paymentCard {
    background-color: white;
    border-radius: 25px;
    width: 100%;
    box-shadow: 0px 0px 12px -7px rgba(0, 0, 0, 0.25);
    border: rgba(0, 0, 0, 0.15) solid 0.1px;
    padding: 35px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.paymentCard>div>h4 {
    margin-top: -5px;
    opacity: 75%;
    font-weight: 500;
}

.statusBtn {
    background-color: #ff0000bb;
    border-radius: 25px;
    font-weight: 600;
    border: #ff0000bb;
    height: 50px;
    box-shadow: 2px 2px 12px -5px rgba(0, 0, 0, 0.5);
}

.statusBtn:hover {
    background-color: #ff000099;
    transition: .25s ease-in-out;
}

.statusBtn:focus {
    background-color: #ff000099;
}

.cardDataRight {
    color: white;
    opacity: 75%;
    margin-top: -10px;
}

.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    font-weight: 500;
}

.cardHeader {
    display: flex;
    justify-content: space-between;
}

.cardTitle {
    font-weight: 700;
    color: #000000aa;

}

.cardMenuButton {
    border-radius: 15px;
    margin-top: -2px;
    font-weight: 900;
}

.userIcon {
    border-radius: 100%;
    border: 1px solid gray;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-top: 2px;
    font-size: 24px;
}

.divider1px {
    border-bottom: 1px solid #eaebf3;
    margin: 10px 0px;
}

.paymentContent h3 {
    font-size: 14px;
    font-weight: 700 !important;
}

.paymentContent h4 {
    font-size: 14px;
    font-weight: 600 !important;
}

.formInput {
    box-shadow: 0 2px 12px 0 rgb(11 22 44 / 5%) !important;
    line-height: 28px !important;
    padding: 10px 15px !important;
}

.formLabel {
    font-size: 14px;
    line-height: 1.143em;
    color: #303350;
    font-weight: 700;
    margin: 10px;
    width: 100%;
}

.divider {
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
    width: 100%;
    /* margin-bottom: 15px; */
}

.btnShadow {
    box-shadow: 3px 5px 20px -7px rgba(0, 0, 0, 0.25);
    font-size: 14px;
    font-weight: 500;
    height: 42px;
    border-radius: 25px;
    padding: 0px 20px;
    min-width: 100px;
    margin: 10px 0px;
}

.docName {
    color: black;
    padding-top: 8px;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
}

.tableStatus {
    height: 10px;
    width: 10px;
    border-radius: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    width: 90%;
    margin: auto;
    margin-top: 25px;
}

h1 {
    font-weight: 700;
    font-size: x-large;
}

h3 {
    font-weight: 600;
}

h4 {
    font-weight: 500;
}

.basicProfileCard h4 {
    color: white;
    font-weight: 600;
    text-transform: uppercase;
}

.basicProfileCard h2 {
    color: white;
    font-weight: 500;
}


.header {
    display: flex;
    justify-content: space-between;
}

.flexBoxRow {
    display: flex;
    align-items: center;
    gap: 15px;

}

.textGray {
    color: #999;
    font-weight: 600;
}

.linkGray {
    color: #888;
    font-weight: 600;
    transition: .25s ease-in-out;
}

.linkGray:hover {
    color: #444;
}

.btnAddProvider {
    background-color: #f87d4e;
    border-radius: 25px;
    font-weight: 600;
    border: #f87d4e;
    height: 50px;
    box-shadow: 2px 2px 12px -5px rgba(0, 0, 0, 0.5);
}

.btnAddProvider:hover {
    background-color: #f87d4ecc;
    transition: .25s ease-in-out;
}

.btnAddProvider:focus {
    background-color: #f87d4ecc;
}

.content {
    margin: 35px 0px;
    display: flex;
    gap: 25px;
}

.profileCard {
    background-color: white;
    width: 70%;
    border-radius: 25px;
    box-shadow: 0px 0px 12px -7px rgba(0, 0, 0, 0.25);
    border: rgba(0, 0, 0, 0.15) solid 0.1px;
    padding: 35px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

::-moz-selection {
    color: #F87D4E;
    background: #F87D4E0f;
}

::selection {
    color: #F87D4E;
    background: #F87D4E0f;
}

.detailsLogoContainer {
    width: 65px;
    height: 65px;
    line-height: 65px;
    border-radius: 25%;
    overflow: hidden;
    text-align: center;
    border: 1px solid #3AB44D33;
    box-shadow: 20px 20px 60px #bebebe99, -20px -20px 60px #ffffff99;
    background-color: white;
    margin-bottom: -10px;
}

.progressSpinContainer {
    height: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profileCardHeader {
    display: flex;
    justify-content: space-between;
}

.basicProfileCard {
    /* background-color: #3AB44D; */
    /* f87d4e */
    border-radius: 25px;
    padding: 35px 25px;

    margin-bottom: 25px;
    /* background-image: url('../../../icons/background.svg'); */
    background-position: 350px 75px;
    background-repeat: no-repeat;
}

.basicProfileCardRed {
    border-radius: 25px;
    padding: 35px 25px;

    margin-bottom: 25px;
    /* background-image: url('../../../icons/background.svg'); */
    background-position: 350px 75px;
    background-repeat: no-repeat;
}

.basicProfileCardRed>*::selection {
    color: #3AB44D !important;
    background: #F87D4E0f;
}

.basicProfileCardRed>*::moz-selection {
    color: #3AB44D !important;
    background: #F87D4E0f;
}

.basicProfileCardBox {
    display: flex;
    justify-content: space-between;
}

.tableContainer {
    border-radius: 25px;
    overflow: hidden;
    border: 1px solid #eaebf3
}

.btnShadow {
    box-shadow: 3px 5px 20px -7px rgba(0, 0, 0, 0.25) !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    height: 40px !important;
    border-radius: 25px !important;
    padding: 0px 20px !important;
}

.sideContent {
    width: 35%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.contactCard {
    background-color: white;
    border-radius: 25px;
    width: 100%;
    box-shadow: 0px 0px 12px -7px rgba(0, 0, 0, 0.25);
    border: rgba(0, 0, 0, 0.15) solid 0.1px;
    padding: 35px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.paymentCard {
    background-color: white;
    border-radius: 25px;
    width: 100%;
    box-shadow: 0px 0px 12px -7px rgba(0, 0, 0, 0.25);
    border: rgba(0, 0, 0, 0.15) solid 0.1px;
    padding: 35px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.paymentCard>div>h4 {
    margin-top: -5px;
    opacity: 75%;
    font-weight: 500;
}

.statusBtn {
    background-color: #ff0000bb;
    border-radius: 25px;
    font-weight: 600;
    border: #ff0000bb;
    height: 50px;
    box-shadow: 2px 2px 12px -5px rgba(0, 0, 0, 0.5);
}

.statusBtn:hover {
    background-color: #ff000099;
    transition: .25s ease-in-out;
}

.statusBtn:focus {
    background-color: #ff000099;
}

.cardDataRight {
    color: white;
    opacity: 75%;
    margin-top: -10px;
}

.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    font-weight: 500;
}

.cardHeader {
    display: flex;
    justify-content: space-between;
}

.cardTitle {
    font-weight: 700;
    color: #000000aa;

}

.cardMenuButton {
    border-radius: 15px;
    margin-top: -2px;
    font-weight: 900;
}

.userIcon {
    border-radius: 100%;
    border: 1px solid gray;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-top: 2px;
    font-size: 24px;
}

.divider1px {
    border-bottom: 1px solid #eaebf3;
    margin: 10px 0px;
}



.formInput {
    box-shadow: 0 2px 12px 0 rgb(11 22 44 / 5%);
    padding: 10px 15px;
    /* height: 32px; */
    line-height: 24px;
    opacity: 75%;
}

.Profile_editSelect__8TvQk>.ant-select-selector {
    height: 10px !important;
}

.timePicker {
    border-radius: 25px 0px 0px 25px;
    height: 32px !important;
    margin-bottom: -10px !important;
    opacity: 75%;
}

.tableStatusContainer {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    line-height: 7px;
    gap: 7px;
    font-weight: 700;
    margin-top: 7px;

}

.tableStatus {
    height: 7px;
    width: 7px;
    border-radius: 100%;
}

.dashlyIcon {
    width: 25px;
    margin: 0px -10px 5px 5px
}