body {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, 'Poppins'; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  background-color: #f8f8f8 !important;

}

@font-face {
  font-family: 'Dashboardly Custom Icons, sans-serif';
  src: local('Lato'), url(../../public/fonts/dashly-icons.woff2) format('woff');
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none !important;
}

.ant-custom-input {
  border-radius: 25px !important;
}

.ant-btn-group {
  --antd-wave-shadow-color: #f87d4e !important;

}

.ant-dropdown-menu {
  border-radius: 20px !important;
  padding: 20px !important;
}

.ant-btn {
  border-radius: 14px;
}

.ant-btn-group.ant-dropdown-button>.ant-btn {
  --antd-wave-shadow-color: #f87d4e55 !important;
  border-color: #f87d4e55;
  color: #f87d4e;
}

.ant-btn-group.ant-dropdown-button>.ant-btn:hover {
  --antd-wave-shadow-color: #f87d4e !important;
  border-color: #f87d4e;
  color: #f87d4e;
}

.ant-btn-group.ant-dropdown-button>.ant-btn:first-child:not(:last-child) {
  border-radius: 10px 0px 0px 10px !important;
}

.ant-btn-group.ant-dropdown-button>.ant-btn:last-child:not(:first-child) {
  border-radius: 0px 10px 10px 0px !important;
}

.ProviderDetail_deleteMenu__Ujz0R>.ant-dropdown-menu-item {
  padding: 10px;
}

.ProviderDetail_deleteMenu__Ujz0R>.ant-dropdown-menu-item-active {
  background-color: #ff0000 !important;
  color: white !important;
  border-radius: 10px !important;
}

.ProviderDetail_deleteMenu__Ujz0R>.ant-dropdown-menu-item-disabled {
  border-radius: 10px !important;
}

.ant-select-selector {
  padding: 5px 15px !important;
  box-shadow: 0 2px 12px 0 rgb(11 22 44 / 5%);
  line-height: 28px;
  height: 50px !important;
  border-radius: 25px !important;
  align-items: center;
  /* padding-right: 15px !important; */
  /* padding-left: 18px !important; */
}

.ant-select-selector:hover {
  border: 1px solid #5dc269 !important;
  /* border-right-width: 1px !important; */
}

.ant-select-arrow {
  right: 20px !important;
}

.ant-select-selection-search-input {
  padding-left: 8px !important;
}

.ant-select-selection-placeholder {
  padding-left: 8px !important;
}

/* ant-radio-group-outline ant-radio-group-small ant-picker-calendar-mode-switch */
.ant-picker-calendar-mode-switch {
  border-radius: 25px !important;
  overflow: hidden;
  border: solid 0.75px gray
}

.ant-radio-button-wrapper {
  border: none !important;
}

.ant-picker-cell-inner {
  border-radius: 25px !important;
}

.ant-input-affix-wrapper>input.ant-input {
  padding-left: 8px !important;
}

.ant-picker {
  box-shadow: 0 2px 12px 0 rgb(11 22 44 / 5%);
  line-height: 28px;
  height: 50px;
  align-items: center;
  padding-right: 15px !important;
  padding-left: 18px !important;
}

.ant-input {
  border-radius: 25px !important;
  width: 100% !important;
  font-size: 14px !important;
  font-weight: 400;
  color: #333333 !important;
  background-color: #ffffff !important;
}


input[type='file'] {
  display: none;
}


.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.uploadedFile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2px;
}

.uploadedFile:hover {
  background-color: rgb(242, 240, 240);
}

.box {
  background-color: white;
  padding: 20px;
  position: relative;
  box-shadow: 2px 2px 2px 1px rgba(229, 226, 226, 0.2);
  /* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(235, 233, 233, 0.22); */
}

.ant-picker-calendar-mini .ant-picker-panel {
  border-radius: 25px !important;
}

.strongLabel {
  font-weight: 700;
}

.strongLabell {
  font-weight: 500;
}

.fieldLabel {
  font-size: 14px;
  font-weight: bold;
}

.wFull {
  width: 100%;
}

.wHalf {
  width: 50%;
}

.mtLarge {
  margin-top: 30px;
}

.mbLarge {
  margin-bottom: 30px;
}

.mlLarge {
  margin-left: 30px;
}

.mrLarge {
  margin-right: 30px;
}

.mbZero {
  margin-bottom: 0px !important;
}

.mbFive {
  margin-bottom: 5px !important;
}

.customAntInput {
  border-radius: 22px !important;
  height: 50px;
}

.custom-ant-button {
  height: 50px !important;
}

.importantIcon {
  object-fit: contain;
  width: 10px;
  height: 17px;
  margin-right: 3px;
}

.dfaCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dfsBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dGrid {
  display: grid;
  place-items: center;
}

.addBox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.addBoxWhite {
  display: grid;
  place-items: center;
  width: 170px;
  height: 40px;
  color: black;
  background-color: #fff;
}

.activeTopNavLi {
  border-bottom: #f87d4e 3px solid;
}

.ant-modal-content {
  /* width: 470px; */
  width: 100%;
  border-radius: 23px !important;
}

.commonModal .ant-modal-content {
  width: 370px !important;
}

.fileClaim .ant-modal-content {
  width: 520px !important;
}

.ant-upload.ant-upload-drag {
  border: none !important;
}

/* .ant-upload.ant-upload-drag {
  border-radius: 20px !important;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: none !important;
  cursor: pointer;
} */

.ant-checkbox-inner {
  border-radius: 40% !important;
  zoom: 1.5;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
}

.ant-checkbox {
  border-radius: 40% !important;
  /* border: 0.15px solid #00000055; */
}



.ant-checkbox-checked .ant-checkbox-inner {
  border-radius: 40% !important;
}

.ant-checkbox-checked:hover {
  border: none !important;
}

.ant-upload.ant-upload-select-picture-card {
  border-radius: 25px;
  box-shadow: 0px 0px 12px -7px rgba(0, 0, 0, 0.25);
  border: 0.25px solid rgba(0, 0, 0, 0.10) !important;
  transition: box-shadow 0.25s ease-in-out;

}

.ant-upload.ant-upload-select-picture-card:hover {
  box-shadow: 0px 0px 12px -7px rgba(0, 0, 0, 0.75);
  transition: box-shadow 0.25s ease-in-out;
}

.modalCloseIcon {
  position: absolute;
  top: 18px;
  right: 18px;
  z-index: 10;
}

.ant-modal-close {
  display: none !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-item {
  border-radius: 50%;
  background-color: #f87d4e;
}

.ant-pagination-item-active a {
  color: white !important;
}

.ant-radio-wrapper-checked span {
  color: #f87d4e !important;
  font-weight: 500;
  border-radius: 25px !important;
}

.ant-divider-horizontal {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.ant-picker-input,
.ant-picker.ant-picker-range {
  display: flex;
  flex-direction: row-reverse;
}

.ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
  margin-left: 0;
  margin-right: 10px;
  color: #f87d4e;
}

.ant-select-dropdown {
  border-radius: 25px !important;
  padding: 10px !important;
}

.ant-picker-panel-container {
  border-radius: 25px !important;
  padding: 10px !important;
}

.ant-picker-dropdown {
  border-radius: 25px !important;
  padding: 10px !important;
}


.ant-select-arrow {
  color: #f87d4e !important;
}

.ant-select-item-option-active {
  border-radius: 15px !important;
}

.ant-select-item {
  border-radius: 15px !important;
}

.ant-collapse-header-text {
  width: 100%;
  padding-bottom: 10px;
  font-weight: 700;
}

.ant-collapse-item-active .ant-collapse-header-text {
  /* border-bottom: 1px solid #3AB44D99; */
}

.support .ant-select-selection-placeholder {
  font-size: 14px !important;
}


.ant-upload-list-item-name {
  max-width: 250px !important;
}

.ant-form-item-control {
  width: 100% !important;
}

.ant-upload-list-item-info {
  border-radius: 10px !important;
  padding-left: 10px !important;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* Ant table css customization starts from here*/

.ant-table-thead>tr>th {
  background-color: white !important;
  border-bottom: 2px solid #f0f0f9 !important;
}

.ant-table-thead .ant-table-cell {
  color: #f87d4e !important;
  font-weight: bold;
  padding-bottom: 6px !important;
}

.ant-table-cell {
  font-size: 13px !important;
  background-color: white !important;
  color: #6f7182;
  font-weight: 500;
}

colgroup {
  min-width: auto !important;
}

col {
  min-width: auto !important;
}

.dashBoardTable .ant-table-thead .ant-table-cell {
  background-color: inherit !important;
}

.inpatientDetail .ant-table-thead .ant-table-cell {
  background-color: inherit !important;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: inherit !important;
}

.ant-table-thead .ant-table-cell {
  background-color: #f7f9fb !important;
  color: #6f7182 !important;
  font-weight: 500;
  text-transform: uppercase;
  padding-bottom: 10px !important;
}

.ant-table-thead .ant-table-cell {
  font-size: 13px !important;
  cursor: auto;
}

.ant-table-cell {
  font-size: 12px !important;
}

.ant-table-cell {
  cursor: pointer;
}

.ant-table-filter-column {
  justify-content: flex-start !important;
}

.ant-table-filter-column .ant-table-column-title {
  flex: inherit;
}

/* Ant table css customization over here*/
.ant-picker-time-panel-cell-selected>.ant-picker-time-panel-cell-inner {
  /* border-radius: 12px !important; */

}

.ant-picker-ok>button {
  border-radius: 7px !important;

}

.ant-btn-primary {
  transition: all 1s ease-in-out;

}

form div.ant-form-item-explain .ant-form-item-explain-error:not(:first-child) {
  display: none;
}

.ant-btn-primary[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
  color: white !important;
  border-color: #3ab44d !important;
  background: #3ab44d !important;
  text-shadow: inherit !important;
  box-shadow: inherit !important;
  transition: opacity 0.25s ease-in-out;
  transform: none !important;
}

.ant-form-item-explain-error {
  padding-left: 10px;
  padding-top: 3px;
  font-size: 13px;
  font-weight: 500;
  color: #f87d4e !important;
  margin-bottom: 25px;
  transition: all 0.25s ease-in-out;
}

@keyframes softLoad {
  0% {
    opacity: 0% !important;
  }

  100% {
    opacity: 100% !important;
  }
}



.helpText {
  animation: 2s ease-out 2s 1 softLoad !important;
  color: red !important;
}

.ant-form-item-with-help {
  height: 70px !important;
  margin-bottom: 5px !important;
}

.ant-form-item-with-help .ant-form-item-explain {
  height: 70px !important;
}

.ant-form-item-explain .ant-select-selection-item {
  border-radius: 15px !important;
}

.ant-select-selection-item {
  border-radius: 15px !important;
}

.ant-upload-picture-card-wrapper {
  width: 48% !important;
}

.ant-upload-select-picture-card {
  border-radius: 25px !important;
  padding: 0px !important;

}

.ant-modal-header {
  border-radius: 25px !important;
}

.img-crop-modal .img-crop-container {
  border-radius: 25px !important;
}

.ant-upload-list-item {
  border-radius: 25px !important;
  /* padding: 0px !important; */
  overflow: hidden !important;
}

.ant-upload-list-item-info {
  padding: 0px !important;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  padding: 0px !important;

}

.ant-modal-footer>button {
  border-radius: 25px !important;
}

.ant-popover-inner {
  background-color: white !important;
  border-radius: 25px !important;
  overflow: hidden;
  padding: 10px !important;
}

.dfaCenter>span {
  margin: 0px 10px;
}

.ant-pagination-options-size-changer>.ant-select-selector {
  height: 25px !important;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover>span>button>span {
  color: white;
  transition: all 0.25s ease-in-out;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-danger>span>button>span {
  color: #ff4d4f;
  transition: all 0.25s ease-in-out;
}

.profileSelect>div>div>div>div>.ant-select-selector {
  height: 32px !important;
  opacity: 75%;
}

.profileSelect>.ant-select-selector {
  height: 32px !important;
  opacity: 75%;
}

.profileSelect>.ant-select-selector>.ant-select-selection-overflow {
  margin-top: -8px;
  justify-content: flex-end;
}

.ant-dropdown-menu-item {
  border-radius: 12px !important;
  padding: 7px 10px !important;
}

.ant-dropdown-menu-root {
  padding: 0px !important;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  line-height: 38px !important;
  padding-top: 0px;
}

.ant-checkbox-wrapper {
  display: flex !important;
  align-items: center !important;
}

.ant-tooltip-inner {
  border-radius: 20px !important;
  padding: 15px !important;
}

.ant-select-selection-search {
  display: flex;
  align-items: center;
}

#benefitsDrop>div>div {
  height: 28px !important;
}

.ant-table-row-expand-icon {
  border-radius: 15px !important;
}

.ant-form-item-explain-connected {
  display: none !important;
}